@import url(https://fonts.googleapis.com/css?family=Kanit:400,400i&display=swap&subset=latin-ext,thai);
body {
  margin: 0;
  font-family: 'Kanit', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Kanit', sans-serif; 
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.logo{
  width: 100% !;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.ant-menu-item a {
    color: rgba(0, 0, 0, 0.85);
}
.ant-menu-item a:hover {
    color: #41aea9;
}
.ant-menu-item-selected {
    color: #41aea9;
}
.ant-menu-item-selected a, .ant-menu-item-selected a:hover {
    color: #41aea9 !important;
}
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #41aea9;
}
.ant-layout-sider-zero-width-trigger {
    background: #213e3b;
    top: 100px;
}

.ant-btn-primary {
    background: #41aea9;
    border-color: #41aea9;
}
.ant-input:hover {
    border-color: #41aea9;
    border-right-width: 1px !important;
}
.ant-btn:hover, .ant-btn:focus {
    color: #41aea9;
    background: #fff;
    border-color: #41aea9;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #41aea9;
}
.ant-select-focused:not(.ant-select-disabled):hover
.ant-select-selector {
    border-color:#41aea9 ;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #41aea9;
    font-weight: 500;
}
.ant-pagination-item-active a {
    color: #41aea9;
}
.ant-pagination-item-active {
    font-weight: 500;
    background: #fff;
    border-color: #41aea9;
}
@media screen and (max-width: 600px) {
    .ant-table-fixed-left, .ant-table-fixed-right{
        position: static;
    }
}
.wrap-name {
    float: left;
    margin-left: 20px;
    padding: 6px;
    font-size: 2em;
}

.select-status {
    width: 160px;
}

.search-event {
    width: 240px !important;
}

.filterTools {
    text-align: right;
    padding: 6px;
}

@media screen and (max-width: 750px) {
    .filterTools {
        margin-bottom: 12px;
        padding: 0;
    }

    .wrap-name {
        margin-left: 40px;
    }

    .select-status {
        width: 100%;
        margin-bottom: 5px;
    }

    .search-event {
        width: 100% !important;
        margin-bottom: 5px;
    }

    .ant-input-group>.search-event:not(:first-child) .ant-input {
        border-radius: 4px;
    }

    .export-event {
        width: 100%;
    }

    .removeTestModeBtn {
        width: 100%;
    }

   /*  .ant-table-wrapper {
        padding: 6px;
    } */

    .ant-table {
        font-size: 12px;
        line-height: 1;
    }
}
  .collapsible {
    display: none;
  }
  
  .collapsible.open {
    display: block;
  }

  .col-margin-top20 {
    margin-top: 20px;
  }

  .img-Card-ststis {
    width: 100%;
    max-width: 200px;
    margin: auto;
    margin-top: 10px;
  }

  .Layout-Content {
    background: #fff;
    padding: 24px;
    margin: 0;
    min-height: 280;
  }

  .chart-pie-cover {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    height: 435px;
    max-height: 100%;
  }

  .chart-bar-description {
    height: 410px;
    max-height: 100%;
  }
.reward-form {
  display: grid;
  padding-top: 2rem;
}
.reward-form-form {
  /* display: inline-flex; */
  padding: 0.5rem;
  /* background-color: aquamarine; */
}
.reward-form-card {
  /* background-color: bisque; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10rem 0;
  /* margin: 20rem auto; */
}

.reward-card-cardline {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  /* background-color: rgba(0, 0, 0, 0); */
  width: 172px;
  height: 235px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content; */
  padding: 0 0;

  border: 4px solid;
}

.reward-card-main {
  display: flex;
  margin-top: 10px;
  padding: 0;
  width: 157px;
  height: 157px;
  /* background-color: green; */
  position: relative;
}

.reward-card-progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reward-card-avatar {
  /* background-color: rgba(255, 192, 203, 0.5); */

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reward-card-point {
  background-color: skyblue;
  position: absolute;
  bottom: 0px;
  left: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #444444;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.reward-card-point p {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

.reward-card-content {
  /* background-color: aquamarine; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  /* margin: 0.5rem; */
}
.reward-card-content p,
.reward-card-content h1 {
  margin: 0;
}

.reward-card-content p {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.reward-card-content h1 {
  font-size: 36px;
  font-weight: 400;
  line-height: 42px;
}

@media (max-width: 768px) {
  .reward-form-card {
    padding: 2rem 0;
  }
}

.content {
    background: #ffffff;
    padding: 24px;
    margin: 0px;
    min-height: 280px;
}

.nav-hamburger {
    display: none;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 35px;
}

.btn {
    margin-right: 10px;
}

.ant-form-item {
    margin-bottom: 7px;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: rgba(33,62,59,0.3) !important; 
}
.ant-menu-dark.ant-menu-horizontal >  .ant-menu-item:hover {
  background-color: rgba(33,62,59,0.3) !important; 
}
@media screen and (max-width: 750px) {
    .form-content {
        padding: 24px !important;
        background-color: #ffffff !important;
    }

    .card-content {
        /* padding: 6px !important; */
        background-color: #f0f2f5 !important;
    }

    .content {
        padding: 6px;
        background-color: #ffffff;
    }
    
    .nav-hamburger {
        display: block;
    }

    .navbar {
        display: none;
    }

    .btn {
        width: 100%;
        margin: 0 !important;
    }

    .ant-form-item {
        margin-bottom: 14px;
    }
}
button.create,
button.add,
button.create:active,
button.add:active {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
button.edit,
button.edit:active {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
button.delete,
button.delete:active {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}
button.back,
button.back:active {
  color: #fff;
  background-color: #616161;
  border-color: #616161;
}
button.action,
button.action:active {
  margin-bottom: 5px;
  display: block;
}

div.borderBottomItem {
  border-bottom: 1px solid #e8e8e8;
}
div.ant-steps-item.ant-steps-item-finish.ant-steps-item-custom div.stepTitle {
  color: #222;
}

div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
  display: inline;
  display: initial;
}
div.ant-steps-horizontal.ant-steps-label-horizontal {
  display: inline-block;
}
div.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
  padding: 16px !important;
  display: flex;
}
div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
  display: inline-block;
}
div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
  display: inline-block;
  margin: 0;
}
div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
  margin: auto;
}
div.stepTitle {
  font-size: 15px;
  line-height: 21px;
  white-space: pre-line;
}
div.eventSteps.ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
  position: absolute;
  left: 16px;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 21px 0 6px;
}
div.eventSteps.ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail:after {
  height: 1px;
  width: 100%;
}
div.eventSteps div.ant-steps-item-custom .ant-steps-item-icon {
  width: 32px;
}
div.eventSteps div.ant-steps-item:last-child .ant-steps-item-icon.ant-steps-item-icon {
  width: auto;
  text-align: left;
}
div.eventSteps div.ant-steps-item:last-child .ant-steps-item-tail.ant-steps-item-tail {
  display: none;
}
div.eventSteps.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: inline;
}
div.ant-steps-item-title {
  display: none;
}
@media only screen and (max-width: 769px) {
  div.stepTitle {
    font-size: 13px;
    line-height: 19px;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    /* width: 52px; */
  }
}
@media only screen and (max-width: 480px) {
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
    display: inline;
    display: initial;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal {
    display: inline-block;
  }
  div.ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
    padding: 8px !important;
    text-align: center;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: inline-block;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: inline-block;
    margin: 0;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    width: 46px;
  }
  div.stepTitle {
    font-size: 10px;
    line-height: 15px;
  }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
    position: absolute;
    left: 16px;
    top: 0;
    height: 100%;
    width: 100%;
    padding: 21px 0 6px;
  }
  div.ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail:after {
    height: 1px;
    width: 100%;
  }
}
div.ant-steps-item-custom.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon button,
div.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon button {
  color: #41aea9;
  border-color: #41aea9;
}
div.eventItem {
  padding: 20px;
}
form.eventForm {
  padding: 20px;
}
div.fixBottomPanel {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: auto;
  z-index: 5;
  background: white;
  border: 1px solid #dad4d4;
  padding: 3px;
  border-radius: 5px;
  left: 0;
  text-align: center;
}

.displayNone {
  display: none;
}

.metaPaymentStatistics thead.ant-table-thead {
  display: none;
}

.metaPaymentSummary thead.ant-table-thead {
  display: none;
}

div.sortableItems .item {
  border: 1px solid #bfbfbf;
  padding: 8px;
  cursor: move;
  max-width: 600px;
  border-radius: 5px;
  background: #f5f5f5;
  margin: 1px;
}
div.sortableItems {
  padding: 8px 0;
}

@media only screen and (max-width: 1001px) {
  div.paymentStatTableWrapper {
    overflow-x: scroll;
  }
  div.paymentStatTableWrapperInner {
    width: 1000px;
  }
}

button.inline {
  display: inline !important;
}
.ant-select-selection.ant-select-selection--single {
  min-width: 230px;
}

div.payments-mobile .ant-form-item-label {
  line-height: 20px !important;
}
div.payments-mobile .ant-form-item-control {
  line-height: 20px !important;
}
div.payments-mobile .ant-form-item {
  margin: 0 !important;
}

div.paymentSummary div.title {
  font-size: 20px;
  padding: 5px;
  margin-bottom: 19px;
}
div.paymentSummary td {
  word-break: break-word;
  border: 1px solid #e8e8e8;
}
div.paymentSummary .sum td {
  border-top: 2px solid black;
  border-bottom: 3px solid black;
}
div.paymentSummary .sumTop td {
  border-top: 2px solid black;
}

div.paymentSummary {
  max-width: 795px;
}

div.paymentSummary .ant-table{
  font-size: 13px;
  font-family: 'Kanit', sans-serif;
}


div.paymentSummary .ant-table-thead > tr > th,
div.paymentSummary .ant-table-tbody > tr > td {
  padding: 7px 8px;
}

div.paymentSummary div.marginTop {
  margin-top: 30px;
}

div.paymentSummary .ant-table-thead > tr > th {
  background: #e0dbdb;
}
div.paymentSummary .onlyHeader tbody {
  display: none;
}

@media print {
  div.paymentSummary .ant-table{
    font-size: 10px;
  }
  aside.ant-layout-sider.ant-layout-sider-dark {
    display: none;
  }
  header.header.ant-layout-header {
    display: none;
  }

  .printPdfBtn {
    display: none;
  }
  body {
    -webkit-print-color-adjust: exact !important;
  }
  thead.ant-table-thead {
    display: table-row-group;
  }
  .ant-layout-content {
    padding-top: 0 !important;
  }
  .filterTools {
    display: none;
  }

}

.disabled {
  pointer-events: none;
  color:#AAA;
  background:#F5F5F5;
}

.ticketGroupWrapper label.bigTitle span {
  font-size: 21px;
  padding: 0 3px;
}
div.ticketGroupWrapper {
  border-radius: 5px;
  padding: 0 2px;
  margin: 0;
  margin-bottom: 3px;
  max-width: 500px;
}
.ticketGroupWrapper .afterTicketGroup {
  padding: 0 30px
}
.ticketGroupWrapper .afterTicketGroup .ant-row.ant-form-item {
  margin: 0;
  margin-bottom: 15px;
}
div.ticketGroupPreviewWrapper {
  border-radius: 5px;
  padding: 0 2px;
  margin: 0;
  margin-bottom: 3px;
}
main.participantForm input,
main.participantForm textarea{
  max-width: 500px;
}
