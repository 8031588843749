  .collapsible {
    display: none;
  }
  
  .collapsible.open {
    display: block;
  }

  .col-margin-top20 {
    margin-top: 20px;
  }

  .img-Card-ststis {
    width: 100%;
    max-width: 200px;
    margin: auto;
    margin-top: 10px;
  }

  .Layout-Content {
    background: #fff;
    padding: 24px;
    margin: 0;
    min-height: 280;
  }

  .chart-pie-cover {
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    height: 435px;
    max-height: 100%;
  }

  .chart-bar-description {
    height: 410px;
    max-height: 100%;
  }