.reward-form {
  display: grid;
  padding-top: 2rem;
}
.reward-form-form {
  /* display: inline-flex; */
  padding: 0.5rem;
  /* background-color: aquamarine; */
}
.reward-form-card {
  /* background-color: bisque; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10rem 0;
  /* margin: 20rem auto; */
}

.reward-card-cardline {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  /* background-color: rgba(0, 0, 0, 0); */
  width: 172px;
  height: 235px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content; */
  padding: 0 0;

  border: 4px solid;
}

.reward-card-main {
  display: flex;
  margin-top: 10px;
  padding: 0;
  width: 157px;
  height: 157px;
  /* background-color: green; */
  position: relative;
}

.reward-card-progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reward-card-avatar {
  /* background-color: rgba(255, 192, 203, 0.5); */

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reward-card-point {
  background-color: skyblue;
  position: absolute;
  bottom: 0px;
  left: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #444444;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.reward-card-point p {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
}

.reward-card-content {
  /* background-color: aquamarine; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  /* margin: 0.5rem; */
}
.reward-card-content p,
.reward-card-content h1 {
  margin: 0;
}

.reward-card-content p {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.reward-card-content h1 {
  font-size: 36px;
  font-weight: 400;
  line-height: 42px;
}

@media (max-width: 768px) {
  .reward-form-card {
    padding: 2rem 0;
  }
}
